:root {
  --primaryMain: #2a0944;
  --primarySecondary: #3b185f;

  --bg: #a12568;
  --fg: #fec260;
  --bbg: #0b0bb1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7%;
  /* background: var(--primaryMain); */
  /* background-color: green; */
  background: linear-gradient(to top left, #28b487, #7dd56f);
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

.header h2 span {
  color: var(--bbg);
}
/* .nav {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  list-style: none;
  text-align: none;
} */
.nav-item {
  text-decoration: none;
  margin-left: 0.85rem;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

@media screen and (max-width: 960px) {
  .nav-item {
    display: block;
  }
}
