body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primaryMain: #2a0944;
  --primarySecondary: #3b185f;

  --bg: #a12568;
  --fg: #fec260;
  --bbg: #0b0bb1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7%;
  /* background: var(--primaryMain); */
  /* background-color: green; */
  background: linear-gradient(to top left, #28b487, #7dd56f);
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

.header h2 span {
  color: #0b0bb1;
  color: var(--bbg);
}
/* .nav {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  list-style: none;
  text-align: none;
} */
.nav-item {
  text-decoration: none;
  margin-left: 0.85rem;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

@media screen and (max-width: 960px) {
  .nav-item {
    display: block;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/1.c6538191.jpeg);
  height: 85vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.typed-details {
  color: #fff;
  font-size: 2rem;
  padding: 0.5rem 7%;
}

.home-details {
  padding: 1rem 7%;
  display: flex;
}
.homeD {
  /* background: linear-gradient(to top left, #dfe6e4, #7dd56f); */
  background: #eee;
  color: #000;
}

.particles {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.footer {
  padding: 5em 7%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.footer-info {
  background: linear-gradient(to top left, #121333, #0c1360);
  /* background: linear-gradient(to top left, #28b487, #7dd56f); */
  /* background:; */
  color: #fff;
}
.footer form {
  display: block;
}
.footer-time h2 span {
  color: gold;
}
.footer-time {
  text-align: center;
}
.contact-form input,
textarea {
  width: 100%;
}

.contact-form input,
textarea {
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
  padding: 0.5rem;
  background: #242554;
  color: #fff;
  border: 1.5px solid #000;
}

.contact-form input::-webkit-input-placeholder {
  text-transform: uppercase;
  color: inherit;
  padding: 0.2rem;
  color: #fff;
}

.contact-form input:-ms-input-placeholder {
  text-transform: uppercase;
  color: inherit;
  padding: 0.2rem;
  color: #fff;
}

.contact-form input::placeholder {
  text-transform: uppercase;
  color: inherit;
  padding: 0.2rem;
  color: #fff;
}
.contact-form textarea::-webkit-input-placeholder {
  text-transform: uppercase;
  color: #fff;
}
.contact-form textarea:-ms-input-placeholder {
  text-transform: uppercase;
  color: #fff;
}
.contact-form textarea::placeholder {
  text-transform: uppercase;
  color: #fff;
}

.copyright {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 0;
  padding-bottom: 2rem;
}
.footer-time a {
  color: #fff;
  font-size: 1.2rem;
  padding: 0.5rem;
  background: inherit;
}
@media screen and (max-width: 768px) {
  .footer {
    display: inline-block;
  }
  .footer-time,
  .footer-contact,
  .contact-form {
    padding: 0.5rem;
    width: 90%;
    text-align: center;
  }
}

.particles {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

