.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./../../img/1.jpeg);
  height: 85vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.typed-details {
  color: #fff;
  font-size: 2rem;
  padding: 0.5rem 7%;
}
